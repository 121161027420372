<template>
  <Toast />
  <div
    class="surface-0 align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden painel"
  >
    <div class="layout-header">
      <Menubar :model="null">
        <template #start>
          <img
            alt="logo"
            src="../../../public/images/sespa/brasaoSespa.svg"
            height="70"
            class="p-mr-4 logo-menu-left logo"
          />
        </template>
        <template #end>
          <img
            alt="logo"
            src="../../../public/images/sespa/logoEstado.svg"
            height="50"
            class="p-mr-4 logo-menu-right logo"
          />
        </template>
      </Menubar>
    </div>

    <div class="grid justify-content-center p-2 lg:p-0">
      <div class="col-12 xl:col-4">
        <div class="h-full w-full m-0 py-8 px-4">
          <form @submit.prevent="signIn" class="col-12 mt-5">
            <div class="h-full w-full m-0 py-7 px-4 shadow-3 pnl-form">
              <div class="text-center mb-5">
                <h2 class="title">INTRANET</h2>
                <div
                  v-if="environment === 'development'"
                  class="user-badge status-false"
                >
                  AMBIENTE DE DESENVOLVIMENTO
                </div>
                <div
                  v-else-if="environment === 'staging'"
                  class="user-badge status-staging"
                >
                  AMBIENTE DE HOMOLOGAÇÃO
                </div>
                <div
                  v-else-if="environment === 'training'"
                  class="user-badge status-training"
                >
                  AMBIENTE DE TREINAMENTO
                </div>
              </div>
              <div class="md:w-10 mx-auto imputs">
                <a for="login" class="font-label" style="color: var(--gray-900)"
                  >Login</a
                >
                <InputText
                  id="login"
                  v-model="user.login"
                  type="text"
                  class="w-full mt-1 mb-2"
                  placeholder="Digite seu Login"
                  style="padding: 1rem"
                  required
                  @input="handleCPFMask"
                />
                <a
                  for="login"
                  class="block font-label mb-1"
                  style="color: var(--gray-900)"
                  >Senha</a
                >
                <Password
                  id="password1"
                  v-model="user.password"
                  placeholder="Digite sua senha"
                  :toggleMask="true"
                  :feedback="false"
                  class="w-full mb-1"
                  inputClass="w-full"
                  inputStyle="padding: 1rem"
                  required
                />
                <div
                  class="flex align-items-center justify-content-between mb-1"
                >
                  <a
                    class="font-medium no-underline mt-2 mb-2 ml-2 ml-2 text-left cursor-pointer"
                    style="color: var(--gray-900)"
                    @click="onCreateUser"
                    >Cadastrar-se</a
                  >
                  <a
                    class="font-medium no-underline mt-2 mb-2 ml-2 text-right cursor-pointer"
                    style="color: var(--gray-900)"
                    @click="onFoggetPassword"
                    >Esqueceu a senha?</a
                  >
                </div>
                <Button
                  label="Entrar"
                  class="p-button-secondary w-full p-3 text-xl"
                  type="submit"
                ></Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="layout-footer fix">
    <span class="footer-text">
      &copy; Todos os Direitos. Coordenação de Tecnologia e Informática em Saúde
      - CTIS. <br />
      Tva. Lomas Valentinas, 2190, Bairro Marco, CEP: 66093-677, Belém, Pará
      Contato: +55, 91 4006-4399 | 4006-4328 | <b>www.saude.pa.gov.br</b>
    </span>
  </div>

  <DialogCreate />
  <DialogFoggetPassword />
</template>
<script>
import Users from "../../models/users";
import AuthService from "../../service/auth/auth_service";

import DialogCreate from "./components/DialogCreate.vue";
import DialogFoggetPassword from "./components/DialogFoggetPassword.vue";

export default {
  components: {
    DialogCreate,
    DialogFoggetPassword,
  },
  data() {
    return {
      authService: new AuthService(),
      user: new Users(),
    };
  },
  created() {
    this.applyLightThemeForLogin();
    const token = sessionStorage.getItem("token");

    if (token) {
      this.$Token
        .checkToken()
        .then(() => {
          this.$router.push("/system");
        })
        .catch((msg) => {
          this.$toast.add({
            severity: "warn",
            summary: "Sessão expirada!",
            detail: msg,
          });
          this.$Token.clearAccessToken();
          this.$router.push("/");
        });
    }

    // Adiciona o ouvinte para o redimensionamento da janela
    window.addEventListener("resize", this.checkWidth);
  },
  beforeUnmount() {
    // Remove o ouvinte ao destruir o componente
    window.removeEventListener("resize", this.checkWidth);
  },
  computed: {
    environment() {
      return process.env.VUE_APP_ENV;
    },
  },

  methods: {
    applyLightThemeForLogin() {
      // Define o tema claro para a tela de login
      const themeLink = document.getElementById("theme-link");
      themeLink.href = `themes/bootstrap4-light-blue/theme.css`;

      // Remove a classe dark-mode do corpo, caso esteja aplicada
      document.body.classList.remove("dark-mode");
    },
    handleCPFMask() {
      const login = this.user.login;

      // Remove tudo que não é número
      const unmaskedLogin = login.replace(/\D/g, "");

      // Verifica se tem 11 dígitos
      if (unmaskedLogin.length === 11) {
        // Adiciona a máscara de CPF
        this.user.login = unmaskedLogin.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );
      } else {
        // Remove a máscara se o número de dígitos for diferente de 11
        this.user.login = unmaskedLogin;
      }
    },
    signIn() {
      const unmaskedLogin = this.user.login.replace(/\D/g, ""); // Remove a máscara antes de enviar
      this.authService
        .login(unmaskedLogin, this.user.password)
        .then(() => this.$router.push("/system"))
        .catch((res) => {
          if (
            res.response.status === 400 &&
            res.response.data.error_description ===
              "Usu&aacute;rio desabilitado"
          ) {
            this.$toast.add({
              severity: "warn",
              summary: "Alerta!",
              detail:
                "Usuário desabilitado, entre em contato com o administrador do sistema!",
            });
          }
          if (res.response.status >= 500) {
            this.$toast.add({
              severity: "error",
              summary: "Alerta!",
              detail: "Entre em contato com o CTIS!",
              life: 6000,
            });
          }
          if (
            res.response.status === 400 &&
            res.response.data.error_description ===
              "Usu&aacute;rio inexistente ou senha inv&aacute;lida"
          ) {
            this.$toast.add({
              severity: "error",
              summary: "Alerta!",
              detail: "Login ou senha incorretos!",
              life: 6000,
            });
          }
        });
    },
    onCreateUser() {
      this.$store.state.views.login.dialogCreate = true;
    },
    onFoggetPassword() {
      this.$store.state.views.login.dialogFoggetPassword = true;
    },
  },
};
</script>
<style scoped>
.grid {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Garante que o conteúdo comece do topo */
  height: 100%; /* Permite que a grid ocupe toda a altura */
}
html,
body {
  height: 100%; /* Garante que o body ocupe a altura total da tela */
  margin: 0; /* Remove margens padrão do body */
}
.fix {
  bottom: 0;
  position: fixed;
  width: 100%;
  text-align: center;
}
.layout-footer {
  background-color: #dddddd;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  height: auto; /* Permite que o footer ajuste sua altura automaticamente */
  padding: 10px 0; /* Adiciona algum espaço ao redor do texto */
  transition: transform 0.3s ease, padding 0.3s ease; /* Anima a descida */
}
.layout-header {
  background-color: #dddddd;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  bottom: 0;
}
.painel {
  height: 98vh;
  background-image: url("../../../public/images/sespa/fb-bg-2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  animation: fade-in 3s;
}
.pnl-form {
  background-color: #ffffff;
  background-image: radial-gradient(#f8f8f8, #ffffff);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  transition: transform 0.3s ease, padding 0.3s ease; /* Anima as mudanças */
  margin-bottom: 50px; /* Para criar espaço entre o formulário e o footer */
}
/* Classe para descer o footer */
.slide-leave-active {
  transform: translateY(100%); /* Mover o footer para baixo */
}

.slide-enter-active {
  transform: translateY(100%); /* Começa fora da tela */
}

.slide-enter, 
.slide-leave-to /* .slide-leave-active em versões anteriores do Vue */ {
  transform: translateY(0); /* Finaliza na posição original */
}

.font-label {
  font-weight: 500;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.footer-text {
  font-size: 16px; /* Tamanho padrão */
  text-align: center; /* Centraliza o texto do footer */
}

@media (max-width: 768px) {
  .footer-text {
    font-size: 14px; /* Tamanho para telas pequenas */
  }

  .logo {
    max-width: 80%; /* Ajuste da logo para telas muito pequenas */
  }
  .pnl-form {
    transform: translateY(-10px); /* Move a div do formulário para cima */
    padding: 2rem; /* Ajusta o padding para melhor visualização */
    margin-bottom: 70px; /* Aumenta o espaço para evitar sobreposição */
  }

  .text-xl {
    font-size: 1.5rem; /* Diminuindo o tamanho da fonte */
  }

  .font-medium {
    font-size: 0.9rem; /* Diminuindo o tamanho da fonte para links */
  }
}

@media (max-width: 480px) {
  .footer-text {
    font-size: 12px; /* Tamanho para telas extra pequenas */
  }
  .logo {
    max-width: 85%; /* Tamanho para telas extra pequenas */
  }
  h2 {
    font-size: 1.5rem; /* Ajusta o tamanho do título */
  }

  .text-xl {
    font-size: 1.25rem; /* Diminuindo ainda mais o tamanho da fonte */
  }

  .font-medium {
    font-size: 0.9rem; /* Diminuindo ainda mais o tamanho da fonte para links */
  }

  .pnl-form {
    transform: translateY(
      -80px
    ); /* Move mais para cima em telas muito pequenas */
    padding: 1rem; /* Ajusta o padding para melhor visualização */
    margin-bottom: 100px; /* Aumenta ainda mais o espaço em telas menores */
  }
}
@media (max-width: 400px) {
  .layout-footer {
    position: relative;
  }

  .pnl-form {
    transform: translateY(-135px); /* Mantém o painel elevado */
    padding: 1rem; /* Ajusta o padding para melhor visualização */
    margin-bottom: 150px; /* Aumenta ainda mais o espaço em telas menores */
  }

  .text-xl {
    font-size: 1rem; /* Diminui o tamanho da fonte para títulos */
  }

  h2 {
    font-size: 0.9rem; /* Ajusta o tamanho do título */
    margin-top: -50px; /* Ajuste o valor conforme necessário para subir o título */
  }

  .imputs {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .user-badge {
    margin-top: -10px; /* Ajuste o valor conforme necessário para subir o ambiente */
    font-size: 0.4rem;
  }

  .font-medium {
    font-size: 0.85rem; /* Reduz o tamanho da fonte para links */
  }

  .font-label {
    font-size: 0.1;
  }

  .footer-text {
    font-size: 10px; /* Reduz ainda mais o tamanho do texto do rodapé */
  }

  .logo {
    max-width: 90%; /* Ajuste da logo para telas muito pequenas */
    transform: translateY(15px);
  }

  .layout-header {
    transform: translateY(-25px); /* Mantém o painel elevado */
  }
}
</style>
