<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: widthDialog }"
    header="Cadastro de usuário"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field text-center">
      <h5 class="p-ml-4">
        <b> TIPO DE PESQUISA: POR {{ typeSearch }}.</b>
      </h5>
      <SelectButton
        v-model="typeSearch"
        :options="options"
        aria-labelledby="single"
      />
    </div>
    <div class="field" v-if="typeSearch === 'MATRÍCULA'">
      <label for="credential">Matrícula</label>
      <InputText
        id="credential"
        v-model="person.credential"
        maxlength="10"
        placeholder="Digite a Matrícula"
      />
    </div>
    <div class="field" v-else>
      <label for="cpf">CPF</label>
      <InputMask
        mask="999.999.999-99"
        id="cpf"
        v-model="person.cpf"
        placeholder="Digite a CPF"
      />
    </div>
    <hr />
    <div v-if="showDetails">
      <details-person :person="person" />
      <br />

      <!--<details-address :person="person" />-->
      <br />
      <Fieldset legend="Criar senha">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="email">E-mail</label>
            <InputText
              id="email"
              type="email"
              v-model="v$.user.email.$model"
              maxlength="255"
              placeholder="Digite o email"
              :class="{
                'p-invalid': submitted && v$.user.email.email.$invalid,
              }"
            />
            <small
              class="p-invalid"
              v-if="submitted && v$.user.email.email.$invalid"
              >Digite um e-mail válido.</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="login">Login</label>
            <InputText
              id="login"
              v-model="v$.user.login.$model"
              placeholder="Digite o Login"
              disabled
              :class="{ 'p-invalid': submitted && !v$.user.login.$model }"
            />
            <small class="p-invalid" v-if="submitted && !v$.user.login.$model"
              >Login é obrigatório.</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="password">Senha</label>
            <Password
              id="password"
              v-model="v$.user.password.$model"
              placeholder="Digite a senha"
              toggleMask
              :class="{ 'p-invalid': submitted && !v$.user.password.$model }"
            >
              <template #footer="sp">
                {{ sp.level }}
                <Divider />
                <p class="mt-2">Sugestões</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>Pelo menos uma minúscula.</li>
                  <li>Pelo menos uma maiúscula.</li>
                  <li>Pelo menos um numérico.</li>
                  <li>Mínimo de 8 caracteres.</li>
                </ul>
              </template>
            </Password>
            <small
              class="p-invalid"
              v-if="submitted && !v$.user.password.$model"
              >Senha é obrigatório.
            </small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="password">Confirmar Senha</label>
            <Password
              id="password"
              v-model="confirmPasword"
              placeholder="Confirme a senha"
              toggleMask
              :class="{
                'p-invalid':
                  submitted && confirmPasword !== v$.user.password.$model,
              }"
            />
            <small
              class="p-invalid"
              v-if="submitted && confirmPasword !== v$.user.password.$model"
              >As senhas devem ser iguais</small
            >
          </div>
        </div>
      </Fieldset>
    </div>

    <template #footer>
      <Button
        label="Pesquisar"
        class="p-button-secondary"
        icon="pi pi-search"
        @click="searchPerson"
      />
      <Button
        label="Salvar"
        class="p-button-success"
        icon="pi pi-check"
        v-if="showDetails"
        @click="send(!v$.user.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Person from "../../../models/person";
import Users from "../../../models/users";

//Services
import PersonService from "../../../service/persons/person_service";
/*import DetailsAddress from "../../persons/components/details-address.vue";*/
import UsersService from "../../../service/user/user_service";

// Components
import detailsPerson from "../../persons/components/details-person.vue";

//VALIDATIONS
import { sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: { detailsPerson },
  data() {
    return {
      person: new Person(),
      user: new Users(),
      submitted: false,
      confirmPasword: null,
      userService: new UsersService(),
      personService: new PersonService(),
      typeSearch: "MATRÍCULA",
      showDetails: false,
      widthDialog: "480px",
      sameAs,
      options: ["MATRÍCULA", "CPF"],
    };
  },
  validations() {
    return {
      user: this.user.validationsCreate(),
      confirmPassword: this.sameAs(this.user.password),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        return this.$store.state.views.login.dialogCreate;
      },
      set(value) {
        this.$store.state.views.login.dialogCreate = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        this.userService
          .createUserAndPassword(this.user)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Alerta!",
                detail: "Usuário cadastrado com sucesso.",
                life: 3000,
              });
              this.$emit("findAll");
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      } else {
        return;
      }
    },
    searchPerson() {
      let key =
        this.person.credential != null
          ? this.person.credential
          : this.person.cpf;

      this.personService
        .findByCpfOrCredential(key)
        .then((data) => {
          this.person = data;
          this.user.person = this.person;
          this.user.login =
            this.person.credential == null
              ? this.person.cpf.replace(/[.-]/g, "") // replace para retirar os . e - do cpf
              : this.person.credential;
          this.user.group = [];
          this.widthDialog = "920px";
          this.showDetails = true;
        })
        .catch(() => {
          //console.error(error);
          this.$toast.add({
            severity: "error",
            summary: "Alerta de Erro.",
            detail: "Registro não encontrado",
            life: 6000,
          });
        });
    },
    hideDialog() {
      this.person = new Person();
      this.user = new Users();
      this.submitted = false;
      this.typeSearch = "MATRÍCULA";
      this.showDetails = false;
      this.widthDialog = "480px";
      this.visibleDialog = false;
    },
  },
};
</script>
<style scoped></style>
